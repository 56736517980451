import React, { useState } from 'react';
import { Modal, Button, Pagination, Dropdown, Tooltip, Form, Input as AntdInput, message, Image, Empty } from 'antd';
import Input from './Input';
import SelectList from './SelectList';
import Upload from './Upload';
import Del from './Del';  //删除弹窗

function ImageDemo(props) {
    const [visible, setVisible] = useState(false);
    return (
        <React.Fragment>
            <img title={props.data.name} alt='' src={props.data.url} />
            {!props.data.selected && <div title={props.data.name} className='ylmask'>
                <span className='yl' onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setVisible(true)
                }}>预览</span>
            </div>}
            <Image
                src={props.data.url}
                style={{ display: 'none' }}
                preview={{
                    visible,
                    src: props.data.url,
                    onVisibleChange: (value) => {
                        setVisible(value);
                    }
                }}
            />
        </React.Fragment>
    )
}

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            visible: false,
            total: 0,
            page: 1,
            size: 10,
            fileType: [
                { value: 1, label: '图片' },
                { value: 2, label: '视频文件' },
                { value: 3, label: 'Execl文件' },
                { value: 4, label: 'Word文件' },
                { value: 5, label: 'PDF文件' },
                { value: 6, label: '压缩文件' },
                { value: 7, label: '未知类型文件' },
            ],
            fileNum: props.fileNum || '',
            multiple: props.multiple ? props.multiple : false,
            createVisible: false,
            loading: false,
            fileName: [{ id: 0, name: '根目录' }],
            pid: 0,
            type: props.type || undefined,
            name: '',
            createLoading: false,
            uploadLoading: false,
            uploadPercent: '',
        }
    }
    componentDidMount() {
        Modal.destroyAll()
        this.getList()
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }
    getList() {
        var req = {
            page: this.state.page,
            size: this.state.size,
            orderBy:"",
            pid: this.state.pid,
            type: this.state.type,
            name: this.state.name,
        }
        global.Ajax.hhtc_request('setting/getFileList', req).then(res => {
            if (res.code == 1) {
                this.setState({
                    total: res.data.all,
                    data: this.initData(res.data.datas),
                })
            }
        })
    }
    initData(arry) {
        let arryNew = []
        arry.map((item, index) => {
            arryNew.push(Object.assign({}, item, { selected: false }))
        })
        return arryNew
    }
    selectFile(index, data, e) {
        if (e.target.className == 'ant-image-preview-operations' || e.target.nodeName == 'path' || e.target.nodeName == 'UL' || e.target.nodeName == 'svg') {  //点击的删除按钮
            return
        }
        if (data.type == 8) {  //文件夹
            return
        }
        var data = this.state.data;
        if (!this.state.multiple) {  //单选
            data = this.initData(data)
        }
        data[index].selected = !data[index].selected;
        this.setState({
            data
        })
    }
    // 打开文件夹
    openFile(index, data) {
        if (data.type == 8) {  //只有文件夹才能双击
            this.state.fileName.push({ id: data.id, name: data.name })
            this.setState({
                fileName: this.state.fileName,
                page: 1,
                pid: data.id,
            }, () => {
                this.getList()
            })
        }
    }
    // 上传文件
    uploadFile(domain, key, name, type, filePosition) {
        this.setState({
            uploadLoading: true,
        }, () => {
            var req = {
                domain: this.state.pid == 0 ? filePosition : 0,
                type: type,
                name: name,
                key: key,
                url: domain + '/' + key,
                pid: this.state.pid,
            }
            global.Ajax.hhtc_request('setting/addFile', req).then(res => {
                if (res.code == 1) {
                    this.getList()
                } else {
                    message.error(res.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({
                        uploadLoading: false,
                    })
                }, 1500);
            })
        })
    }
    localOk(url,name,type,key){
        this.setState({
            uploadLoading: true,
        },()=>{
            var req={
                domain: 4,
                type,
                name,
                url,
                key,
                pid: this.state.pid,
            }
            global.Ajax.hhtc_request('setting/addFile',req).then(res=>{
                if(res.code==1){
                    this.getList()
                }else{
                    message.error(res.msg,1.2)
                }
                setTimeout(() => {
                    this.setState({
                        uploadLoading: false,
                    })
                }, 1500);
            })
        })
    }
    // 删除文件
    del(data) {
        var url = 'setting/delFile', req = { id: data.id }
        this.delmask.del(url, req)
    }
    // 搜索文件
    search(name) {
        this.setState({
            name: name,
            page: 1,
        }, () => {
            this.getList()
        })
    }
    render() {
        return (
            <React.Fragment>
                <Modal
                    visible={this.state.visible}
                    width={824}
                    title={(<div className='flexCenter'>
                        <p>文件库:</p>
                        {this.state.fileName.map((item, index) => (
                            <span key={'' + index} className={index != this.state.fileName.length - 1 ? 'filename' : ''} onClick={() => {
                                if (index != this.state.fileName.length - 1) {  //返回当前目录
                                    var newArr = this.state.fileName.slice(0, (index + 1));
                                    this.setState({
                                        fileName: newArr,
                                        pid: item.id,
                                    }, () => {
                                        this.getList()
                                    })
                                }
                            }}>{item.name}{index != this.state.fileName.length - 1 ? '/' : ''}</span>
                        ))}
                    </div>)}
                    centered={true}
                    destroyOnClose={true}
                    footer={null}
                    closeIcon={(<p className='iconfont icon-guanbi'></p>)}
                    onCancel={this.onCancel.bind(this)}
                >
                    <div>
                        <div className='flexCenter'>
                            <Input
                                placeholder='请输入文件名称'
                                onChange={(e) => {
                                    if (e.target.value == '') {
                                        this.search('')
                                    }
                                }}
                                onPressEnter={(e) => {
                                    if (e.target.value != '') {
                                        this.search(e.target.value)
                                    }
                                }}
                            />
                            <SelectList
                                data={this.state.fileType}
                                placeholder='请选择文件类型'
                                className='marginr12'
                                style={{ width: 160 }}
                                allowClear={!this.props.type ? true : false}
                                disabled={this.props.type ? true : false}
                                value={this.state.type}
                                onChange={(type) => {
                                    console.log(type)
                                    this.setState({
                                        type,
                                        page: 1,
                                    }, () => {
                                        this.getList()
                                    })
                                }}
                            />
                            <Upload
                                onOk={this.uploadFile.bind(this)}
                                onPercent={(val) => {
                                    // console.log(val)
                                    this.setState({
                                        uploadPercent: parseInt(val) + '%'
                                    })
                                    setTimeout(() => {
                                        if (val >= 100) {
                                            this.setState({
                                                uploadPercent: ''
                                            })
                                        }
                                    }, 1000);
                                }}
                                onLocalOk={this.localOk.bind(this)}
                            >
                                <Button type='primary'>上传文件{this.state.uploadPercent}</Button>
                                {this.state.uploadPercent != '' && <span className='jdttt' style={{ width: this.state.uploadPercent }} onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}></span>}
                            </Upload>
                            <Button loading={this.state.createLoading} type='primary' className='createfile' onClick={() => {
                                this.setState({
                                    createVisible: true,
                                })
                            }}>创建文件夹</Button>
                        </div>
                        <div className='uploadlist'>
                            {this.state.data.length == 0 && <Empty style={{ margin: '0 auto' }} />}
                            {this.state.data.map((item, index) => (
                                <div className='item' key={'' + index} onClick={this.selectFile.bind(this, index, item)} onDoubleClick={this.openFile.bind(this, index, item)}>
                                    <div className='kk' style={{ border: (item.type == 8 || item.type == 1) ? '0' : '' }}>
                                        {/* 图片 */}
                                        {item.type == 1 && <React.Fragment>
                                            <ImageDemo data={item} del={this.del.bind(this)} />
                                        </React.Fragment>}
                                        {/* 视频 */}
                                        {item.type == 2 && <React.Fragment>
                                            <span style={{ color: 'rgb(124, 142, 238)' }} className='iconfont icon-shipinwenjian sanj'></span>
                                        </React.Fragment>}
                                        {/* execl文件 */}
                                        {item.type == 3 && <React.Fragment>
                                            <span style={{ color: '#4CB050' }} className='iconfont icon-excelwenjian sanj'></span>
                                        </React.Fragment>}
                                        {/* word文件 */}
                                        {item.type == 4 && <React.Fragment>
                                            <span style={{ color: '#4876F9' }} className='iconfont icon-wordwenjian sanj'></span>
                                        </React.Fragment>}
                                        {/* PDF文件 */}
                                        {item.type == 5 && <React.Fragment>
                                            <span style={{ color: '#FA4E4E' }} className='iconfont icon-PDFwenjian sanj'></span>
                                        </React.Fragment>}
                                        {/* 压缩文件 */}
                                        {item.type == 6 && <React.Fragment>
                                            <span style={{ color: '#576A95' }} className='iconfont icon-yasuobaowenjian sanj'></span>
                                        </React.Fragment>}
                                        {/* 未知类型文件 */}
                                        {item.type == 7 && <React.Fragment>
                                            <span style={{ color: 'rgb(204, 204, 204)' }} className='iconfont icon-weizhiwenjian sanj'></span>
                                        </React.Fragment>}
                                        {/* 虚拟文件夹 */}
                                        {item.type == 8 && <React.Fragment>
                                            <span title='双击打开文件夹' style={{ color: '#ffa000' }} className='iconfont icon-wenjianjia wjj'></span>
                                        </React.Fragment>}
                                        {/* 选中蒙层 */}
                                        {item.type != 8 && item.selected && <div className='mask'>
                                            <span className='iconfont icon-xuanze'></span>
                                        </div>}
                                        {/* 删除 */}
                                        <span className='iconfont icon-cuowu closeIcon' onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            this.del(item)
                                        }}></span>
                                    </div>
                                    <p>{item.name}</p>
                                </div>
                            ))}
                        </div>
                        {this.state.total > 0 && <Pagination
                            style={{ padding: '16px 0 0' }}
                            total={this.state.total}
                            pageSize={this.state.size}
                            showTotal={(total, range) => {
                                return `共${total}条记录，本页展示${range[0]}-${range[1]}条记录`
                            }}
                            onChange={(page) => {
                                this.setState({
                                    page,
                                }, () => {
                                    this.getList()
                                })
                            }}
                        />}
                        <div className='btnbox flexCenter flexEnd' style={{ marginTop: this.state.total == 0 ? 48 : '' }}>
                            <Button className='huibtn marginr8' onClick={() => {
                                this.setState({
                                    visible: false,
                                    data: this.initData(this.state.data),  // 初始化数据组
                                })
                            }}>取消</Button>
                            <Button type='primary' onClick={this.onOk.bind(this)}>确定</Button>
                        </div>
                    </div>
                </Modal>
                {/* 创建文件夹 */}
                <Modal
                    visible={this.state.createVisible}
                    title="创建文件夹"
                    width={360}
                    centered={true}
                    destroyOnClose={true}
                    closeIcon={(<p className='iconfont icon-guanbi'></p>)}
                    footer={null}
                    onCancel={this.onClose.bind(this)}
                >
                    <Form
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="name" label="文件夹名称：">
                            <AntdInput autoComplete="off" placeholder='请输入文件夹名称' type='text' />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </Modal>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.getList()
                }} />
            </React.Fragment>
        )
    }
    onFinish = (values) => {
        this.setState({
            loading: true,
            createLoading: true,
        }, () => {
            values.domain = 0;
            values.type = 8;
            values.key = '';
            values.url = '';
            values.pid = this.state.pid;
            global.Ajax.hhtc_request('setting/addFile', values).then(res => {
                if (res.code == 1) {
                    this.onClose()
                    this.getList()
                } else {
                    message.error(res.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        createLoading: false,
                    })
                }, 1500);
            })
        })
    }
    onOk() {
        // 返回选中文件
        var data = this.state.data, files = [];
        for (var i in data) {
            if (data[i].selected) {
                if (this.props.type == data[i].type) {  //判断选择的文件是否是需要的
                    files.push(data[i].url)
                }
            }
        }
        this.props.onOk(files)
    }
    onCancel() {
        this.setState({
            visible: false,
            data: this.initData(this.state.data)
        })
    }
    onClose() {
        this.setState({
            createVisible: false,
        })
    }
}