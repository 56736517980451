import React from 'react';
import { Form, Input, Button, message } from 'antd';
export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "鸿鹄科技管理后台",
            imgUrl: "",
            uuid: "",
            loading: false
        }
    }
    componentDidMount() {
        this.changeImgUrl();
        global.Ajax.hhtc_request("login/getSystemName", {}).then(data => {
            if (data.code === 1) {
                this.setState({
                    name: data.data.name,
                })
            }
        })
    }
    changeImgUrl() {
        global.Ajax.hhtc_request("login/getCaptcha", {}).then(data => {
            if (data.code === 1) {
                this.setState({
                    uuid: data.data.uuid,
                    imgUrl: data.data.img
                })
            }
        })
    }
    onFinish = (values) => {
        values.uuid = this.state.uuid;
        this.setState({
            loading: true
        }, () => {
            global.Ajax.hhtc_request("login/login", values).then(data => {
                setTimeout(() => {
                    this.setState({
                        loading: false
                    })
                }, 1500)
                if (data.code === 1) {
                    localStorage.setItem("honghuToken", data.data.token)
                    message.success("登录成功")
                    this.props.history.replace('/rout')
                } else {
                    message.error(data.msg)
                    this.changeImgUrl()
                }
            })
        })
    }
    render() {
        return (
            <div className='loginBox'>
                <h1>登录到<br />{this.state.name}</h1>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                >
                    <Form.Item name="username" rules={[{ required: true, message: '请输入账号!' }]}>
                        <Input
                            prefix={<span className='iconfont icon-yonghu'></span>}
                            placeholder="请输入账号"
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: '请输入密码!' }]}>
                        <Input.Password
                            prefix={<span className='iconfont icon-mima'></span>}
                            type="password"
                            placeholder="请输入密码"
                            autoComplete="new-password"
                        />
                    </Form.Item>
                    <div className='flex'>
                        <Form.Item name="code" rules={[{ required: true, message: '请输入图形验证码!' }]}>
                            <Input
                                prefix={<span className='iconfont icon-a-yanzhengmaanquan'></span>}
                                type="text"
                                placeholder="请输入图形验证码"
                                autoComplete="off"
                            />
                        </Form.Item>
                        <div className='code'><img onClick={this.changeImgUrl.bind(this)} className='verifyCodeImg' src={this.state.imgUrl} /></div>
                    </div>
                    <Button loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">登录</Button>
                </Form>
            </div>
        )
    }
}
