import React from 'react';
import { Button, Tooltip, Modal } from 'antd'
import AddRole from './AddRole';  //添加角色
import TabbleList from '../../common/TabbleList';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: 10,
			visible: false,
			orderBy: '',
			row: {
				ids: '[]',
				role_name: '',
				describe: ''
			},
		}
	}
	refresh(){
		this.tabblelist.onRefresh()
	}
	changeVisible(key) {
		this.setState({
			[key]: true
		})
	}
	getList(page, callback) {
		var orderBy = '';
		if (this.state.orderBy != '') {
			orderBy = this.state.orderBy;
		}
		var req = {
			page: page,
			size: this.state.size,
			orderBy: orderBy,
		}
		global.Ajax.hhtc_request('role/roleList', req).then(res => {
			if (res.code == 1) {
				callback(res.data)
			}
		})
	}

	// 编辑
	edit(data) {
		this.setState({
			type: 'edit',
			visible: true,
			row: data,
		})
	}
	// 删除
	del(data) {
		var url = 'role/delRole', req = { role_id: data.role_id }
		this.delmask.del(url, req)
	}
	onRefresh(page, callback) {
		this.getList(page, callback)
	}
	renderHead() {
		return (
			<div className='head flex'>
				<p className='flexAllCenter cursor' style={{ width: 80 }}>
					序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
						var orderBy = this.state.orderBy;
						if (orderBy == 'desc') {
							orderBy = 'asc'
						} else {
							orderBy = 'desc'
						}
						this.setState({
							orderBy
						}, () => {
							this.refresh()
						})
					}}></span>
				</p>
				<p className='row2'>角色名称</p>
				<p className='rowFlex'>角色描述</p>
				<p className='row2'>添加时间</p>
				<p className='row15'>操作</p>
			</div>
		)
	}
	renderItem(data) {
		return (
			<React.Fragment>
				{data.map((item, index) => (
					<div className='flex' key={'' + index}>
						<p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
						<p className='row2'>{item.role_name}</p>
						<Tooltip
							placement='top'
							title={`${item.describe}`}
						>
							<p className='rowFlex'>{item.describe}</p>
						</Tooltip>
						<p className='row2'>{item.atime}</p>
						<div className='row15 flexAllCenter'>
							<Button
								type='primary'
								icon={(<span className='iconfont icon-bianji marginr4'></span>)}
								onClick={this.edit.bind(this, item)}
							>编辑</Button>
							<Button
								type='primary'
								icon={(<span className='iconfont icon-shanchu marginr4'></span>)}
								className='fu1 marginl8'
								onClick={this.del.bind(this, item)}
							>删除</Button>
						</div>
					</div>
				))}
			</React.Fragment>
		)
	}
	render() {
		return (
			<React.Fragment>
				<Button type='primary' onClick={this.changeVisible.bind(this, 'visible')}>添加角色</Button>
				<div className='bgbai'>
					<h2 className='pubTit'>角色列表</h2>
					<TabbleList
						ref={dom => this.tabblelist = dom}
						size={this.state.size}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						renderHead={this.renderHead.bind(this)}  //表头
						renderItem={this.renderItem.bind(this)}  //表内容
					/>
				</div>
				{/* 添加角色 */}
				<CustomModal
					visible={this.state.visible}
					width={900}
					title={`${this.state.type == 'edit' ? '编辑' : '添加'}角色`}
					onCancel={this.onCancel.bind(this)}
				>
					<AddRole type={this.state.type} data={this.state.row} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
				</CustomModal>
				{/* 删除弹窗 */}
				<Del ref={dom => this.delmask = dom} onRefresh={() => {
					this.refresh()
				}} />
			</React.Fragment>
		)
	}
	onOk() {
		this.onCancel()
		this.refresh()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: '',
			row: {
				ids: '[]',
				role_name: '',
				describe: ''
			}
		})
	}
}
