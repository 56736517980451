export default class Helper{
    static getNum(index,total,size,page,orderBy){
       
        var num=index+1;
		if(orderBy=="desc"){
			num=total-(page-1)*size-(num-1);
		}else{
			num=(page-1)*size+num
		}
		if (num < 10) {
			num = '0' + num;
		}
		return num
    }
}