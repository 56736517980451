import { message } from 'antd';
import fetch from "isomorphic-fetch";

export default class Ajax {
    hhtc_request(url,data){
        // console.log(global.httpUrl)
        var requrl=global.httpUrl+"/admin/"+url;
        var init={
            method: 'POST',
            // mode: 'cors',
            // credentials: 'include',
            headers:new Headers({
                'Content-Type': 'application/json;',
                "Accept": "application/json",
                'token':localStorage.getItem('honghuToken')||""
            }),
            body:JSON.stringify(data)
        }
        return  fetch(requrl,init).then(
             (response) => {
                return  response.json()
            }
        ).then(
            (data)=>{
                if(data.code===999){
                    if(localStorage.getItem('honghuToken')){
                        localStorage.removeItem('honghuToken')
                       
                    }
                    message.error(data.msg,1,()=>{
                        window.location.href=''
                    })
                    return data;
                }
                return data;
            }
        ).catch(
            (err)=>{
                console.log("执行一次网络请求:请求地址=》",requrl,"返回结果=>网络异常",err)
                var data={
                    code:0,
                    msg:'网络异常!'
                }
                return data;
            }
        )
    }
}