import React from 'react';
import { Button, Pagination, Modal, message, Empty, Spin } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import AddAdmin from './AddAdmin';  //添加管理员
import TabbleList from '../../common/TabbleList';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: 10,
			visible: false,
			orderBy: '',
			username: '',
			role_id: '',
			type: '',
			editData: {},
			reqFinished: false,
		}
	}
	refresh(){
		this.tabblelist.onRefresh()
	}
	initData(arry) {
		let arryNew = []
		arry.map((item, index) => {
			arryNew.push(Object.assign({}, item, { value: item.role_id, label: item.role_name }))
		})
		return arryNew
	}
	getList(page, callback) {
		var orderBy = '';
		if (this.state.orderBy != '') {
			orderBy = this.state.orderBy;
		}
		var req = {
			page: page,
			size: this.state.size,
			username: this.state.username,
			role_id: this.state.role_id,
			orderBy
		}
		global.Ajax.hhtc_request('admin/adminList', req).then(res => {
			if (res.code == 1) {
				callback(res.data)
			}
		})
	}
	changeVisible(key) {
		this.setState({
			[key]: true
		})
	}

	// 编辑
	edit(data) {
		this.setState({
			editData: data,
			type: 'edit',
			visible: true,
		})
	}
	// 删除
	del(data) {
		var url = 'admin/delAdmin', req = { admin_id: data.admin_id }
		this.delmask.del(url, req)
	}
	// 搜索
	search(name) {
		this.setState({
			username: name,
			page: 1,
		}, () => {
			this.refresh()
		})
	}
	onRefresh(page, callback) {
		this.getList(page, callback)
	}
	renderHead() {
		return (
			<div className='head flex'>
				<p className='flexAllCenter cursor' style={{ width: 80 }}>
					序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
						var orderBy = this.state.orderBy;
						if (orderBy == 'desc') {
							orderBy = 'asc'
						} else {
							orderBy = 'desc'
						}
						this.setState({
							orderBy
						}, () => {
							this.refresh()
						})
					}}></span>
				</p>
				<p className='rowFlex'>用户昵称</p>
				<p className='row15'>上次登录时间</p>
				<p className='row15'>上次登录IP</p>
				<p className='row1'>角色</p>
				<p className='row15'>添加时间</p>
				<p className='row15'>操作</p>
			</div>
		)
	}
	renderItem(data) {
		return (
			<React.Fragment>
				{data.map((item, index) => (
					<div className='flex' key={'' + index}>
						<p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
						<p className='rowFlex'>{item.username}</p>
						<p className='row15'>{item.last_login_time || '-'}</p>
						<p className='row15'>{item.last_login_ip || '-'}</p>
						<p className='row1'>{item.role_name}</p>
						<p className='row15'>{item.atime || '-'}</p>
						<div className='row15 flexAllCenter'>
							<Button
								type='primary'
								icon={(<span className='iconfont icon-bianji marginr4'></span>)}
								onClick={this.edit.bind(this, item)}
							>编辑</Button>
							<Button
								type='primary'
								icon={(<span className='iconfont icon-shanchu marginr4'></span>)}
								className='fu1 marginl8'
								onClick={this.del.bind(this, item)}
							>删除</Button>
						</div>
					</div>
				))}
			</React.Fragment>
		)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入用户昵称'
						className='borderbai'
						onChange={(e) => {
							this.search(e.target.value)
						}}

					/>
					<SelectList
						type="allrole"
						className='marginr12 borderbai'
						style={{ width: 160 }}
						onChange={(role_id) => {
							this.setState({
								role_id,
								page: 1,
							}, () => {
								this.refresh()
							})
						}}
					/>
					<Button type='primary' onClick={this.changeVisible.bind(this, 'visible')}>添加管理员</Button>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>管理员列表</h2>
					<TabbleList
						ref={dom => this.tabblelist = dom}
						size={this.state.size}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						renderHead={this.renderHead.bind(this)}  //表头
						renderItem={this.renderItem.bind(this)}  //表内容
					/>
				</div>
				{/* 添加管理员 */}
				<CustomModal
					visible={this.state.visible}
					width={360}
					title={`${this.state.type == 'edit' ? '编辑' : '添加'}管理员`}
					onCancel={this.onCancel.bind(this)}
				>
					<AddAdmin data={this.state.editData} type={this.state.type} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
				</CustomModal>
				{/* 删除弹窗 */}
				<Del ref={dom => this.delmask = dom} onRefresh={() => {
					this.refresh()
				}} />
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: '',
			editData: {}
		})
	}
}
